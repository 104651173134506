@use '../../styles/shared/include' as *;

.formContainer {
	fieldset {
		display: flex;
		flex-direction: column;
		input {
			border: 1px solid $black;
		}

		textarea {
			height: rem(104);
			border: 1px solid $black;
		}
		span {
			font-size: 12px;
			color: red;
			height: 24px;
		}
		.error {
			margin-top: rem(24);
			color: red;
		}
		button {
			letter-spacing: rem(0.7);
		}
		button {
			letter-spacing: rem(0.7);
		}
		select {
			width: 100%;
			padding: rem(13) rem(23) !important;
			font-size: rem(16);
			font-weight: 500 !important;
			line-height: rem(20);
			letter-spacing: rem(0.3);
			-webkit-appearance: none;
			appearance: none;
			background: $dropdown no-repeat
				calc(100% - rem(23)) !important;
		}
	}

	@media #{$lg} {
		width: 50%;
	}
	:global {
		.is__pageloader {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			// background-color: $periwinkleGrey;
			figure {
				width: rem(60);
				@media #{$lg} {
					width: rem(100);
				}
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}

