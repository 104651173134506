@use '../../styles/shared/include' as *;

.formstepper {
	:global {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		z-index: 999;
		overflow-y: auto;
		.formstepper__bg {
			background-color: $deepBlue;
			color: $white;
			&.theme__yellow {
				background-color: $brightYellow;
				.formstepper__wrapper {
					.formstepper__header {
						background-color: $brightYellow;
						.action_buttons {
							.l_close {
								color: $deepBlue;
								color: $deepBlue;
								.svg {
									path {
										stroke: $deepBlue !important;
									}
								}
							}
						}
					}
				}
				.formstepper__body_End_Wrapper {
					max-height: calc(100vh - 260px);
					overflow-y: auto;
					&.h1_name {
						word-break: break-word;
					}
					@media (max-height: 568px) {
						.h1.h1_name {
							word-break: break-word;
							font-size: rem(30);
							margin-bottom: rem(6);
						}
						p {
							font-size: rem(20);
						}
						a.l-btn {
							scale: 0.8;
							margin-top: rem(10);
						}
						.formstepper__footer {
							position: static;
						}
					}
				}
				.formstepper__body {
					.h1 {
						color: $deepBlue;
					}
					p {
						color: $deepBlue;
					}
					.l-btn {
						display: inline-block;
						border-radius: rem(100);
						border: rem(1) solid $deepBlue;
						line-height: 100%;
						font-weight: 500;
						color: $deepBlue;
						margin-top: rem(18);
						padding: rem(12) rem(23);
						font-size: rem(16);
						@include transition();
						@media #{$md} {
							margin-top: rem(28);
							padding: rem(15) rem(23);
							font-size: rem(20);
						}
						&:hover {
							background-color: $deepBlue;
							color: $brightYellow;
						}
					}
				}
				.formstepper__footer {
					justify-content: center;
					@media #{$md} {
						padding-bottom: rem(80);
					}
					h6 {
						font-family: $secondary;
						font-weight: 800;
						text-transform: uppercase;
						letter-spacing: rem(2.4);
						line-height: 100%;
						color: $deepBlue;
						font-size: rem(16);
						text-align: center;
					}
				}
			}
		}
		.formstepper__wrapper {
			padding: rem(30) 0;
			position: relative;
			height: var(--window-height, 100vh);
			min-height: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			@media #{$lg} {
				padding: rem(64) 0;
			}
			.formstepper__header {
				background-color: $deepBlue;
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				padding: rem(30) 0 rem(16);
				pointer-events: none;
				z-index: 5;
				@media #{$lg} {
					padding: rem(64) 0 rem(16);
				}
				img {
					cursor: pointer;
				}
				.action_buttons {
					display: flex;
					align-items: center;
					gap: 12px;
					@media #{$md} {
						gap: 26px;
					}

					.l_close {
						background: transparent !important;
						display: flex;
						width: auto;
						position: relative;
						align-items: center;
						color: $white;
						gap: rem(4);
						font-family: $primary;
						font-size: rem(16);
						font-weight: 500;
						line-height: 100%;
						cursor: pointer;
						@include transition();
						@media #{$md} {
							width: auto;
							margin-top: -10.7px;
							top: rem(5);
							gap: rem(8);
							font-size: rem(20);
						}
						.svg {
							width: rem(24);
							height: auto;
							path {
								@include transition();
								stroke: $white !important;
							}
						}
						&:hover {
							color: $brightYellow;
							svg {
								path {
									stroke: $brightYellow !important;
								}
							}
						}
					}
				}
				button {
					display: inline-block;
					pointer-events: all;
					&.l_back {
						background: transparent !important;
						display: flex;
						align-items: center;
						color: $white;
						gap: rem(4);
						font-family: $primary;
						font-size: rem(16);
						font-weight: 500;
						line-height: 100%;
						cursor: pointer;
						@include transition();
						@media #{$lg} {
							gap: rem(8);
							font-size: rem(20);
						}
						.svg {
							width: rem(20);
							position: relative;
							@media #{$lg} {
								width: rem(24);
							}
							path {
								@include transition();
							}
						}
						&:hover {
							color: $brightYellow;
							.svg {
								path {
									stroke: $brightYellow !important;
								}
							}
						}
					}
				}
				.l_logo {
					cursor: pointer;
					display: inline-block;
					pointer-events: all;
					width: rem(130);
					@media #{$lg} {
						width: rem(166.22);
					}
					img {
						width: 100%;
						height: auto;
					}
				}
			}

			.formstepper__body {
				.checkbox_error {
					display: block;
					color: #ff5c5c;
					font-size: rem(14);
					margin: rem(12) 0 0;
					font-weight: 300;
					max-width: 100%;
					text-align: center;
					@media #{$lg} {
						font-size: rem(20);
						text-align: right;
					}
				}
				text-align: center;
				@media #{$xl} {
					min-width: rem(720);
				}
				.icon__list {
					display: flex;
					justify-content: center;
					flex-wrap: wrap;
					gap: rem(16);
					margin-bottom: rem(55);
					li {
						figure {
							width: rem(64);
							height: rem(64);
							.svg {
								height: 100%;
								width: 100%;
								path {
									fill: $periwinkleGrey !important;
								}
							}
						}
					}
				}
				.h1 {
					color: $white;
					line-height: 100%;
					font-weight: 300;
					font-size: rem(40);
					margin-bottom: rem(12);
					@media #{$lg} {
						font-size: rem(80);
						margin-bottom: rem(28);
					}
					b {
						font-weight: 300;
						color: $brightYellow;
					}
					@media #{$md-m} {
						br {
							display: none;
						}
					}
					@media #{$md-m} {
						br {
							display: none;
						}
					}
					&.h1_name {
						word-break: break-word;
					}
				}
				p {
					color: $periwinkleGrey;
					line-height: 100%;
					font-weight: 300;
					font-size: rem(40);
					margin: 0;
					margin: 0 auto;
					font-size: rem(20);
					max-width: 100%;
					@media #{$lg} {
						font-size: rem(40);
						max-width: rem(960);
					}
				}
				.formstepper__form {
					display: flex;
					flex-wrap: wrap;
					margin: 0 auto;
					max-width: rem(720);
					row-gap: rem(18);
					justify-content: center;
					margin-top: rem(20);
					@media #{$md} {
						row-gap: rem(28);
						column-gap: rem(40);
					}
					@media #{$lg} {
						margin-top: rem(28);
					}
					.form__group {
						width: 100%;
						.error {
							display: block;
							color: #ff5c5c;
							font-size: rem(14);
							margin: rem(12) 0 0;
							font-weight: 300;
							text-align: left;
						}
						label {
							font-weight: 300;
							color: $white;
							line-height: 100%;
							font-size: rem(16);
							display: flex;
							margin-bottom: rem(7);
						}
						input {
							width: 100%;
							border-radius: rem(3);
							background-color: $white;
							padding: rem(10) rem(14);
							font-size: rem(16);
							font-weight: 300;
							line-height: 100%;
							color: $deepBlue;
							outline: none;
							box-shadow: none;
							border: none;
							&::placeholder {
								color: #406477;
								opacity: 1;
							}
							@media #{$md} {
								padding: rem(15) rem(24);
								font-size: rem(20);
							}
						}
						select {
							width: 100%;
							border-radius: rem(3);
							background-color: $white;
							padding: rem(10) rem(14);
							font-size: rem(16);
							font-weight: 300;
							line-height: 100%;
							color: $periwinkleGrey;
							outline: none;
							box-shadow: none;
							border: none;
							appearance: none;
							background-image: $chevronDown;
							background-repeat: no-repeat;
							background-size: rem(20);
							background-position: calc(100% - 10px) center;
							cursor: pointer;
							color: $deepBlue;
							@media #{$md} {
								background-size: rem(24);
								background-position: calc(100% - 20px) center;
								padding: rem(16) rem(24);
								font-size: rem(20);
							}
							&:focus {
								outline: 3px solid $brightYellow;
								outline-offset: 3px;
							}
						}
						&.w_50 {
							width: 100%;
							@media #{$md} {
								width: calc(50% - (40px / 2));
							}
						}
					}
				}
				.icon__card {
					width: auto;
					display: flex;
					flex-wrap: wrap;
					margin: rem(30) rem(-5) 0;
					max-height: 40vh;
					overflow-y: auto;
					@media #{$md} {
						max-height: 41vh;
						margin: rem(64) rem(-8) 0;
					}
					li {
						padding: 0 rem(5);
						width: 50%;
						margin-bottom: rem(10);
						@media #{$nm} {
							width: 33.33%;
						}
						@media #{$sml} {
							width: 25%;
						}
						@media #{$md} {
							padding: 0 rem(8);
							margin-bottom: rem(16);
							width: 20%;
						}
						.icon__card-item {
							position: relative;
							height: 100%;
							button {
								background-color: $deepBlue;
								width: 100%;
								height: 100%;
							}
							label {
								height: 100%;
								position: relative;
								cursor: pointer;
								border: rem(2) solid $periwinkleGrey;
								border-radius: rem(6);
								z-index: 1;
								display: flex;
								align-items: center;
								flex-direction: column;
								justify-content: center;
								text-align: center;
								gap: rem(4);
								padding: rem(10);
								@include transition();
								@media #{$md-m} {
									padding: rem(12) rem(8);
									border-width: rem(1);
									flex-direction: row;
									justify-content: flex-start;
								}
								figure {
									width: rem(50);
									height: rem(50);
									@media #{$md-m} {
										width: rem(32);
										height: rem(32);
									}
									@media #{$lg} {
										width: rem(64);
										height: rem(64);
									}
									.svg {
										width: 100%;
										height: 100%;
										path {
											fill: $periwinkleGrey !important;
											@include transition();
										}
									}
								}
								p {
									color: $white;
									line-height: 140%;
									font-weight: 300;
									margin: 0;
									font-size: rem(16);
									font-family: $primary;
									@include transition();
									@media #{$md-m} {
										font-size: rem(14);
										line-height: 140%;
										font-weight: 500;
									}
									@media #{$xl} {
										font-size: rem(20);
									}
								}
							}
							input {
								padding: 0;
								height: initial;
								width: initial;
								margin-bottom: 0;
								display: none;
								position: absolute;
								top: 0;
								left: 0;
								height: 100%;
								width: 100%;
								opacity: 0;
								z-index: 2;
								cursor: pointer;
								&:checked + label {
									border-color: $brightYellow;
									figure {
										.svg {
											path {
												fill: $brightYellow !important;
											}
										}
									}
									p {
										font-weight: 500;
									}
								}
							}
						}
					}
				}
			}

			.formstepper__body__StepEight {
				display: flex;
				flex-direction: column;
				max-height: calc(100vh - 190px);
				overflow: hidden;
				overflow-y: auto;
				@media #{$nm-m} {
					.h1 {
						padding-top: rem(30);
					}
				}
				&::-webkit-scrollbar {
					width: rem(5);
				}
				&::-webkit-scrollbar-thumb {
					background-color: $periwinkleGrey;
				}
				padding-top: rem(20);
				@media #{$md} {
					height: calc(100vh - 320px);
				}
				@media #{$xl} {
					margin-top: rem(-35);
				}
				@media screen and (max-height: 935px) {
					padding-right: rem(4);
				}
				@media screen and (min-height: 970px) {
					justify-content: center;
				}
				.icon__card {
					max-height: unset !important;
					z-index: 10;
					@media #{$sml-m} {
						padding-bottom: rem(40);
					}
				}
			}
			.formstepper__body__StepSeven {
				display: flex;
				flex-direction: column;
				max-height: calc(100vh - 190px);
				overflow-y: auto;
				&::-webkit-scrollbar {
					width: rem(5);
				}
				&::-webkit-scrollbar-thumb {
					background-color: $periwinkleGrey;
				}
				@media #{$md} {
					height: calc(100vh - 320px);
				}
				@media #{$xl} {
					margin-top: rem(-35);
				}
				@media screen and (min-height: 800px) {
					justify-content: center;
				}
			}
			.formstepper__footer {
				position: absolute;
				bottom: 0;
				right: 0;
				width: 100%;
				padding: rem(30) 0;
				display: flex;
				justify-content: flex-end;
				pointer-events: none;
				@media #{$md} {
					padding: rem(66) 0;
				}
				.l_continue {
					z-index: 99;
					border-radius: rem(100);
					background-color: $brightYellow;
					padding: rem(10) rem(24);
					display: flex;
					align-items: center;
					gap: rem(5);
					font-size: rem(20);
					font-weight: 500;
					line-height: 100%;
					color: $deepBlue;
					cursor: pointer;
					pointer-events: all;
					letter-spacing: rem(1);
					@media #{$md-m} {
						width: 100%;
						justify-content: center;
						font-size: rem(16);
						padding: rem(16) rem(24);
					}
					@media #{$md} {
						gap: rem(8);
						font-size: rem(20);
					}
					img {
						width: rem(50);
						@media #{$md-m} {
							display: none;
						}
						@media #{$md} {
							width: rem(60);
						}
					}
				}
			}
		}
	}
}
