@use '../../styles/shared/include' as *;

.loader_css {
  :global {
    .page__loader{
      .svg{
        width: rem(100);
        height: rem(100);
      }
      &.theme__yellow{
        .svg{
          circle{
            stroke: $deepBlue;
          }
        }
      }
    }
  }
}

